import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { EngagementSDKBaseEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EngagementSDKBaseEvent) => void,
) => {
  const createAndSendEvent = (
    properties: EngagementSDKBaseEvent['properties'],
  ) => {
    enrichAndSendEvent({
      event: 'engagement.sdk.executed',
      properties,
    });
  };

  dispatcher.on('visitor.sdk.executed', createAndSendEvent);
};
