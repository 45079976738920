import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { EmploymentSurveyBaseEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EmploymentSurveyBaseEvent) => void,
) => {
  const createAndSendEvent =
    (eventName: EmploymentSurveyBaseEvent['event']) =>
    (payload: {
      chatId: string;
      conversationId: string | null;
      reward?: string;
    }) => {
      enrichAndSendEvent({
        event: eventName,
        properties: {
          chatId: parseInt(payload.chatId, 10),
          conversationId: payload.conversationId || undefined,
          rewardId: payload.reward,
        },
      });
    };

  dispatcher.on(
    'dataPipeline.surveyNPSDisplayed',
    createAndSendEvent('conversations.surveyNPS.displayed'),
  );

  dispatcher.on(
    'dataPipeline.surveyNPSSubmitted',
    createAndSendEvent('conversations.surveyNPS.submitted'),
  );

  dispatcher.on(
    'dataPipeline.surveyCSATDisplayed',
    createAndSendEvent('conversations.surveyCSAT.displayed'),
  );

  dispatcher.on(
    'dataPipeline.surveyCSATSubmitted',
    createAndSendEvent('conversations.surveyCSAT.submitted'),
  );

  dispatcher.on(
    'dataPipeline.surveyCommentDisplayed',
    createAndSendEvent('conversations.surveyComment.displayed'),
  );

  dispatcher.on(
    'dataPipeline.surveyCommentSubmitted',
    createAndSendEvent('conversations.surveyComment.submitted'),
  );

  dispatcher.on(
    'dataPipeline.surveyRewardDisplayed',
    createAndSendEvent('employment.surveyReward.displayed'),
  );

  dispatcher.on(
    'dataPipeline.surveyRewardSubmitted',
    createAndSendEvent('employment.surveyReward.submitted'),
  );
};
