import { isVisitorIdentityServiceDefined } from '../live/src/amd/requireGuards';
import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { VisitorIdentityService } from '../live/src/visitor-identity/VisitorIdentityService';

import { getIdz } from '../shared/globals';
import { EngagementVisitorAuthenticationBaseEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EngagementVisitorAuthenticationBaseEvent) => void,
) => {
  const visitorIdentityService = getIdz().require<VisitorIdentityService>(
    'visitorIdentityService',
  );

  if (!isVisitorIdentityServiceDefined(visitorIdentityService)) {
    return;
  }

  dispatcher.on('auth.authenticatedSessionStarted', async ({ jwtIdentity }) => {
    const anonymousVuid = await visitorIdentityService.getAnonymousVuid();

    if (!anonymousVuid || !jwtIdentity?.type) {
      return;
    }

    enrichAndSendEvent({
      event: 'engagement.visitor.authentication.authenticated.session.started',
      properties: {
        authenticationType: jwtIdentity.type,
        authenticatedVuid: jwtIdentity ? jwtIdentity.vuid : null,
        anonymousVuid,
      },
    });
  });

  dispatcher.on(
    'auth.authenticatedSessionFinished',
    async ({ jwtIdentity, cause }) => {
      const anonymousVuid = await visitorIdentityService.getAnonymousVuid();

      if (!anonymousVuid || !jwtIdentity?.type) {
        return;
      }

      enrichAndSendEvent({
        event:
          'engagement.visitor.authentication.authenticated.session.finished',
        properties: {
          authenticationType: jwtIdentity.type,
          authenticatedVuid: jwtIdentity ? jwtIdentity.vuid : null,
          anonymousVuid,
          cause,
        },
      });
    },
  );
};
