import { getIdz, getBuildHttpUrl } from '../shared/globals';

import listenTargetingActionsEvents from './targetingActionsEvents';
import listenRuleEvents from './targetingRulesEvents';
import listenChatboxEvents from './chatboxEvents';
import listenSurveyActionsEvents from './surveyActionsEvents';
import listenVisitorNavigatedEvents from './visitorNavigatedEvents';
import listenAuthenticationEvents from './authenticationEvents';
import listenCookiesActionsEvents from './cookiesActionsEvents';
import listenGDPRActionsEvents from './gdprActionsEvents';
import listenSDKEvents from './sdkEvents';
import listenAnonymousEvents from './anonymousEvents';

import handleVisitorCapabilities from './visitorCapabilities';
import {
  DataPipelineAnonymousEvent,
  DataPipelineBaseEvent,
  DataPipelineEvent,
} from './types';
import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { VisitorIdentityService } from '../live/src/visitor-identity/VisitorIdentityService';
import { isVisitorIdentityServiceDefined } from '../live/src/amd/requireGuards';
import enrichmentsFactory from './enrichments';
import { ExpressedConsent } from '../shared/types/consent';
import { PublicPropertiesStore } from '../shared/types/publicMethods';

export default function initialize(
  dispatcher: DispatcherWithRegistry,
  publicPropertiesStore: PublicPropertiesStore,
  cookieConsent: ExpressedConsent,
  sendEventsOnInit: boolean,
) {
  const eventsCollectorUrl =
    getIdz()?.eventsCollector?.url ||
    getBuildHttpUrl()('engagement/live/events');

  const visitorIdentityService = getIdz().require<VisitorIdentityService>(
    'visitorIdentityService',
  );

  if (!isVisitorIdentityServiceDefined(visitorIdentityService)) {
    return;
  }

  const {
    enrichWithVisitorState,
    enrichWithDeviceId,
    enrichWithCoookieConsent,
  } = enrichmentsFactory(visitorIdentityService);

  const sendEvent = (event: DataPipelineEvent | DataPipelineAnonymousEvent) => {
    fetch(eventsCollectorUrl, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(event),
    });
  };

  const enrichAndSendEvent = (event: DataPipelineBaseEvent) => {
    enrichWithVisitorState(event)
      .then(enrichWithDeviceId)
      .then(enrichWithCoookieConsent)
      .then(sendEvent);
  };

  const listenEvents = () => {
    listenRuleEvents(dispatcher, enrichAndSendEvent);
    listenChatboxEvents(dispatcher, enrichAndSendEvent);
    listenAuthenticationEvents(dispatcher, enrichAndSendEvent);
    listenSurveyActionsEvents(dispatcher, enrichAndSendEvent);
    listenCookiesActionsEvents(dispatcher, enrichAndSendEvent);
    listenGDPRActionsEvents(dispatcher, enrichAndSendEvent);
    listenTargetingActionsEvents(dispatcher, enrichAndSendEvent);
    listenVisitorNavigatedEvents(
      dispatcher,
      enrichAndSendEvent,
      sendEventsOnInit,
    );
    listenSDKEvents(dispatcher, enrichAndSendEvent);

    handleVisitorCapabilities(dispatcher);

    dispatcher.emit('dataPipeline.started');
  };

  //________________________________________________________________________
  listenAnonymousEvents(dispatcher, sendEvent);
  if (cookieConsent) {
    listenEvents();
  } else {
    publicPropertiesStore.on(
      'visitor:cookiesConsent',
      (consent: ExpressedConsent) => {
        if (consent) {
          listenEvents();
        }
      },
    );
  }
}
