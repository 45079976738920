import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';

const checkVisitorCapabilities = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return {
      audioInput: devices.some((device) => device.kind === 'audioinput'),
      videoInput: devices.some((device) => device.kind === 'videoinput'),
    };
  } catch {
    return { audioInput: false, videoInput: false };
  }
};

export default async (dispatcher: DispatcherWithRegistry) => {
  const capabilities = await checkVisitorCapabilities();
  if (capabilities.audioInput) {
    dispatcher.emit('dataPipeline.visitorMicroCapability');
  }
  if (capabilities.videoInput) {
    dispatcher.emit('dataPipeline.visitorCameraCapability');
  }
};
