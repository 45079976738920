import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { EngagementRuleBaseEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EngagementRuleBaseEvent) => void,
) => {
  const createAndSendRuleEvent =
    (eventName: EngagementRuleBaseEvent['event']) => (ruleId: string) =>
      enrichAndSendEvent({
        event: eventName,
        properties: {
          engagementRuleId: `${ruleId}`,
        },
      });

  dispatcher.on(
    'dataPipeline.engagementRuleCountExecute',
    createAndSendRuleEvent('engagement.rule.executed'),
  );

  dispatcher.on(
    'dataPipeline.engagementRuleCountMissedDisplay',
    createAndSendRuleEvent('engagement.rule.displayMissed'),
  );

  dispatcher.on(
    'dataPipeline.engagementRuleCountCappedDisplay',
    createAndSendRuleEvent('engagement.rule.displayCapped'),
  );
};
