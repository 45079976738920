import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { EngagementVisitorCookieBaseEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EngagementVisitorCookieBaseEvent) => void,
) => {
  dispatcher.on(
    'EngagementVisitorCookieConsentRefused',
    (consentSource: 'chatbox' | 'WebSDK') => {
      enrichAndSendEvent({
        event: 'engagement.visitor.cookie.cookieConsentRefused',
        properties: {
          consentSource,
        },
      });
    },
  );
  dispatcher.on(
    'EngagementVisitorCookieConsentAccepted',
    (consentSource: 'chatbox' | 'WebSDK', ttl?: number) => {
      enrichAndSendEvent({
        event: 'engagement.visitor.cookie.cookieConsentAccepted',
        properties: {
          consentSource,
          ttl,
        },
      });
    },
  );
};
