import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { ConversationsChatboxBaseEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  sendEvent: (event: ConversationsChatboxBaseEvent) => void,
) => {
  dispatcher.on('dataPipeline.chatboxTrackingEvent', (event) =>
    sendEvent({
      event: event.event,
      properties: event.properties,
    }),
  );

  dispatcher.on('dataPipeline.visitorCameraCapability', () =>
    sendEvent({
      event: 'conversations.chatbox.videobox.visitorCameraCapability',
      properties: {},
    }),
  );

  dispatcher.on('dataPipeline.visitorMicroCapability', () =>
    sendEvent({
      event: 'conversations.chatbox.videobox.visitorMicroCapability',
      properties: {},
    }),
  );
};
