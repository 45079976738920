import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import {
  EngagementVisitorGDPRBaseEvent,
  EngagementVisitorGdprConsentType,
} from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EngagementVisitorGDPRBaseEvent) => void,
) => {
  dispatcher.on(
    'EngagementVisitorGDPRConsentRefused',
    (
      consentSource: 'chatbox' | 'WebSDK',
      consentType?: EngagementVisitorGdprConsentType,
    ) => {
      enrichAndSendEvent({
        event: 'engagement.visitor.gdpr.gdprConsentRefused',
        properties: {
          consentSource,
          consentType,
        },
      });
    },
  );
  dispatcher.on(
    'EngagementVisitorGDPRConsentAccepted',
    (
      consentSource: 'chatbox' | 'WebSDK',
      consentType?: EngagementVisitorGdprConsentType,
    ) => {
      enrichAndSendEvent({
        event: 'engagement.visitor.gdpr.gdprConsentAccepted',
        properties: {
          consentSource,
          consentType,
        },
      });
    },
  );
};
