import { getUseExplicitCookiesConsent } from '../entry/explicitCookiesConsent/explicitCookiesConsent';
import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { getStorageType } from '../live/src/storage/state/storageType';
import { EngagementTagBaseEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EngagementTagBaseEvent) => void,
  sendEventsOnInit: boolean,
) => {
  const createAndSendEvent = (url: string) => {
    enrichAndSendEvent({
      event: 'engagement.tag.loaded',
      properties: {
        storageType: getStorageType()!,
        referrer: document.referrer,
        tagVersion: 'FULL',
        url,
        useExplicitCookiesConsent: getUseExplicitCookiesConsent(),
        buildVersion: process.env.BUILD_IDENTIFIER!,
      },
    });
  };

  const oldLocation = document.location.href;

  if (sendEventsOnInit) {
    createAndSendEvent(oldLocation);
  }
};
