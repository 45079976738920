import { NotificationTemplate } from '@iadvize/notifications-library/esm/entities/notification';
import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { TargetingAction } from '../shared/types/rules';
import { EngagementNotificationBaseEvent } from './types';

const getChannels = (action: TargetingAction) => {
  if (action.type === 'templated_notification') {
    return action.parameters.type === 'FIXED'
      ? [action.parameters.button.channel?.toLowerCase()]
      : action.parameters.buttons.map((button) =>
          button.channel?.toLowerCase(),
        );
  }

  return ['tag'];
};

export default (
  dispatcher: DispatcherWithRegistry,
  enrichAndSendEvent: (event: EngagementNotificationBaseEvent) => void,
) => {
  const createAndSendEvent = (
    eventName: EngagementNotificationBaseEvent['event'],
    action: TargetingAction & { notification: NotificationTemplate },
    ruleId: number,
    cause?: string,
  ) => {
    enrichAndSendEvent({
      event: eventName,
      properties: {
        notificationId: `${action.id}`,
        notificationType: action.type,
        notificationChannels: getChannels(action),
        engagementRuleId: `${ruleId}`,
        notificationTemplateId: action?.notification?.id,
        cause,
      },
    });
  };

  dispatcher.on('notification.displayed', ({ action, ruleId }) => {
    createAndSendEvent('engagement.notification.displayed', action, ruleId);
  });

  dispatcher.on('notification.clicked', ({ action, ruleId }) => {
    createAndSendEvent('engagement.notification.clicked', action, ruleId);
  });

  dispatcher.on('notification.hidden', ({ action, ruleId, cause }) => {
    createAndSendEvent('engagement.notification.hidden', action, ruleId, cause);
  });

  dispatcher.on('notification.closed', ({ action, ruleId }) => {
    createAndSendEvent('engagement.notification.closed', action, ruleId);
  });

  dispatcher.on('notification.minimized', ({ action, ruleId }) => {
    createAndSendEvent('engagement.notification.minimized', action, ruleId);
  });

  dispatcher.on('notification.maximized', ({ action, ruleId }) => {
    createAndSendEvent('engagement.notification.maximized', action, ruleId);
  });
};
