import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { getStartersState } from '../livechat/src/conversationFlow/startersState';
import { getIdz } from '../shared/globals';
import { DataPipelineAnonymousEvent } from './types';

export default (
  dispatcher: DispatcherWithRegistry,
  sendEvent: (event: DataPipelineAnonymousEvent) => void,
) => {
  //__________________________________________________________
  dispatcher.on('notification.clicked', () => {
    const startersState = getStartersState();
    if (!startersState) {
      return;
    }
    const { notificationId, starterId, starters } = startersState;
    const idz = getIdz();
    sendEvent({
      event: 'engagement.visitor.conversation.starter.clicked',
      properties: {
        availableStarterIds: starters
          .map((starter) => starter.id!)
          .filter(Boolean),
        clickedStarterId: starterId!,
        notificationId,
        projectId: `${idz.platform}-${idz.website_id}`,
        visitorLanguage: idz.curlang,
      },
    });
  });

  //_______________________________________________________________________
  dispatcher.on(
    'EngagementVisitorGDPRConsentDisplayed',
    (consentSource, consentType) => {
      const idz = getIdz();
      sendEvent({
        event: 'engagement.visitor.gdpr.gdprConsentDisplayed',
        properties: {
          projectId: `${idz.platform}-${idz.website_id}`,
          visitorLanguage: idz.curlang,
          consentSource,
          consentType,
        },
      });
    },
  );
};
