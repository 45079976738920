/*______________________________*/
import { getIdz } from '../shared/globals';
import {
  DataPipelineBaseEvent,
  WithDeviceId,
  WithUserCoookieConsent,
  WithVisitorState,
} from './types';
import getDeviceInfo from '../shared/DeviceInformationExtractor';
import { VisitorIdentityService } from '../live/src/visitor-identity/VisitorIdentityService';
import { ExpressedConsent } from '../shared/types/consent';
import { getStringConstantFromExpressedConsent } from '../shared/consentHelpers';
import { getUseExplicitCookiesConsent } from '../entry/explicitCookiesConsent/explicitCookiesConsent';

const { browser, deviceType } = getDeviceInfo();
let currentDeviceId: string | null = null;

export default (visitorIdentityService: VisitorIdentityService) => {
  const enrichWithVisitorState = <
    DataPipelineEvent extends DataPipelineBaseEvent,
  >(
    event: DataPipelineEvent,
  ): Promise<DataPipelineEvent & WithVisitorState> => {
    const { platform, website_id, vuid, curlang } = getIdz();
    return Promise.resolve({
      ...event,
      properties: {
        visitorDate: new Date().toISOString(),
        projectId: `${platform}-${website_id}`,
        sourceVisitorId: vuid,
        visitorLanguage: curlang,

        device: deviceType,
        browser,
        url: document.location.href,

        ...event.properties,
      },
    });
  };

  const enrichWithDeviceId = async <
    DataPipelineEvent extends DataPipelineBaseEvent,
  >(
    event: DataPipelineEvent,
  ): Promise<DataPipelineEvent & WithDeviceId> => {
    if (!currentDeviceId) {
      currentDeviceId = await visitorIdentityService.getDeviceId();
    }

    return {
      ...event,
      properties: {
        ...event.properties,
        deviceId: currentDeviceId,
      },
    };
  };

  const enrichWithCoookieConsent = async <
    DataPipelineEvent extends DataPipelineBaseEvent,
  >(
    event: DataPipelineEvent,
  ): Promise<DataPipelineEvent & WithUserCoookieConsent> => {
    const cookieConsent: ExpressedConsent = getUseExplicitCookiesConsent()
      ? await visitorIdentityService.getCookieConsent()
      : true;

    return {
      ...event,
      properties: {
        ...event.properties,
        cookieConsent:
          getStringConstantFromExpressedConsent(cookieConsent) || 'unknown',
      },
    };
  };

  return {
    enrichWithVisitorState,
    enrichWithDeviceId,
    enrichWithCoookieConsent,
  };
};
